<template>

      <Page>

        <template v-slot:actions>
            <Actions no-menu :filter="filter"/>
        </template>


        <template v-slot:title>
            Icons - opticon
        </template>
        <template v-slot:content>
            <v-row class="pt-2">            
                <v-col class="col-3" v-for="icon in filteredIcons" :key="icon.type" >
                    <v-list-item dense>
                        <v-list-item-avatar class="mt-1 mb-1 ml-0 mr-1">
                            <Icon :type="icon.type"/>
                        </v-list-item-avatar>
                        <v-list-item-content >
                        <v-list-item-title dense v-text="icon.icon"></v-list-item-title>
                        <v-list-item-subtitle dense v-text="markup(icon)"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
              </v-col>              
          </v-row>
        </template>

      </Page>
</template>

<script>

import Page from '@/views/optitest/page'
import Icon from '@controls/icons/Icon'
import Actions from '@shared/ui/main/PageActions.vue'
import libIcon from '@app/icon'

export default {
    name: 'PurchaseMailAll',
    components: {
        Page, Icon, Actions
    }, 
    data() {
        return {
            filter: {q: ""},
            icons: [],
        } 
    },

    methods: {
        markup: function(icon) {
            return `<Icon type="${icon.type}"></Icon>`
        }            
    },
    computed: {
        filteredIcons() {      

            return this.icons.filter( (icon) => {
                return this.filter.q == null  || this.filter.q == "" || icon.type.indexOf(this.filter.q) >=0 || icon.icon.indexOf(this.filter.q) >=0;
            })  
        }
    },
    mounted() {
        var lst = [];
        for (var key in libIcon.types) {
            lst.push({type: key, icon: libIcon.types[key]});
        }
        this.icons = lst;
    },
    setup(props) {
    }

}
</script>
