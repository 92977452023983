import { render, staticRenderFns } from "./PageActions.vue?vue&type=template&id=6e2b1acc&"
import script from "./PageActions.vue?vue&type=script&setup=true&lang=js&"
export * from "./PageActions.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../frontend.kantoor.mobile/app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports